import { React, useEffect, useState } from 'react'
import { useRoutes, useNavigate } from "react-router-dom";
import AirDrop from '../components/AirDrop';
import Announcement from '../components/Announcement';
import Login from '../components/Login';
import Partner from '../components/Partner';
import PreSale from '../components/PreSale';
import PreSaleComponent from '../components/PreSaleComponent';
import Profile from '../components/Profile';
// import Login from "../Component/Auth/Login";
// import Register1 from "../Component/Auth/Register1";
import Index from '../pages';
const MainRoutes = ({ socket }) => {
  const Redirect = useNavigate()
 const state= localStorage.getItem("status")

  const routes = useRoutes([
    { path: "/", element: <Index /> },
    { path: "/presale", element: <PreSaleComponent /> },
    { path: "/airdrop", element: <AirDrop /> },
    { path: "/profile", element: <Profile /> },
    { path: "/announcement", element: <Announcement /> },
    { path: "/partner", element: <Partner /> },


    // { path: "/login", element: <Login /> },   


    // { path: "/login", element: <Login socket={socket}/> },
  ])
  return routes
}

export default MainRoutes