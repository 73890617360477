import React from 'react'

const CheckLike = async(users) => {
    var myHeaders = new Headers();
    var response = await fetch("/api/get_airdrop_data", {
        method: "GET",
        
    });
    var res_data = await response.json();
 
    const user = await fetch(`/api/tweet/liking_users/${res_data.airdrop_data[0].twitter_like}`)
    const res_user = await user.json()
    if (res_user.data) {
        const found = res_user.data.some(el => el.id === users)
        if (found) {
            return { status: true }
        }
    }
}

export default CheckLike