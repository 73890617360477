import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Breadcrumb, Button, Card, Col, Form, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap'

import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
const Profile = () => {
    const [state, setState] = useState({
        "wallet_address": "",
        "first_name": "",
        "last_name": "",
        "referral_code": ""
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const id = sessionStorage.getItem('userId')
    const getData = async () => {
        var response = await fetch(`/api/get_user_wallet/${id}`);
        var res_data = await response.json();
        setState({
            "wallet_address": res_data.get_user_data[0].wallet_address,
            "first_name": res_data.get_user_data[0].first_name,
            "last_name": res_data.get_user_data[0].last_name,
            "referral_code": res_data.get_user_data[0].referral_code
        })
    }
    //connection successfully notify
    const notifyMess = (data) => {
        if (data.status === true) {
            toast.success(data.message);
        }
        else {
            toast.error(data.message);
            // console.log(data.message);
        }
    };
    const handleClick = async () => {
        var response = await fetch(`/api/edit_user_wallet/${id}`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(state),
        });
        var res_data = await response.json();
        if (res_data) {


            notifyMess(res_data)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <ToastContainer />
            <Layout>
                {!state.wallet_address ? (
                   <div class="loading">Loading&#8230;</div>
                ) : (
                    <div class="section  " id="section-contacts-form" style={{ marginTop: "84px" }}>
                        <div class="content">

                            {/* <!-- title --> */}
                            <div class="title">
                                <div class="title_inner">Profile</div>
                            </div>

                            {/* <!-- form --> */}
                            <div class="contact_form content-box">

                                <Row>
                                    <Col md={12} xl={12} xs={12} sm={12}>
                                        {/* <!--div--> */}
                                        <Card>
                                            <Card.Body>

                                                <Row className="row-sm">
                                                    <div className="col-lg">
                                                        <FormGroup className="form-group">
                                                            <Form.Label htmlFor="exampleInputEmail1">
                                                                First Name
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                className="form-control"
                                                                id="exampleInputEmail1"
                                                                placeholder="Enter Full Name"
                                                                name="first_name"
                                                                value={state.first_name}
                                                                onChange={handleChange}
                                                            />

                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-lg">
                                                        <FormGroup className="form-group">
                                                            <Form.Label htmlFor="exampleInputEmail1">
                                                                Last Name
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                className="form-control"
                                                                id="exampleInputEmail1"
                                                                placeholder="Enter Last Name"
                                                                name="last_name"
                                                                value={state.last_name}
                                                                onChange={handleChange}


                                                            />

                                                        </FormGroup>
                                                    </div>

                                                </Row>
                                                <Row className="row-sm mt-4">
                                                    <div className="col-lg">
                                                        <FormGroup className="form-group">
                                                            <Form.Label htmlFor="exampleInputEmail1">
                                                                Wallet Address
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                className="form-control"
                                                                id="exampleInputEmail1"
                                                                readOnly
                                                                name="wallet_address"
                                                                value={state.wallet_address}

                                                            />

                                                        </FormGroup>
                                                    </div>


                                                </Row>
                                                <Row className="row-sm mt-4">
                                                    <div className="col-lg">
                                                        <FormGroup className="form-group">
                                                            <Form.Label htmlFor="exampleInputEmail1">
                                                                Referral Link
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                className="form-control"
                                                                id="exampleInputEmail1"
                                                                readOnly
                                                                name="referral_link"
                                                                value={window.location.origin + "?ref=" + state.referral_code}
                                                            />

                                                        </FormGroup>
                                                    </div>


                                                </Row>
                                                <div class="bts" style={{ marginTop: "30px" }}><a href="#" class="btn hover-animated" onClick={handleClick}>
                                                    <span class="circle">
                                                        <span class="ink">
                                                        </span>
                                                    </span>
                                                    <span class="lnk">Update</span>
                                                </a>
                                                </div>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                        <div class="clear"></div>
                    </div>
                )}
            </Layout>
        </>
    )
}

export default Profile