


const Web3 = require('web3');
/*
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
*/
const web3 = new Web3("https://data-seed-prebsc-2-s3.binance.org:8545/");

const contractABI = require('../presale.json')

const BN = require('bn.js');

export const connectWallet = async () => {

    if (window.ethereum) {
        try {
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            if (chainId != 0x61) {

                try {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: "0x61" }]
                    });

                } catch (switchError) {
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainId: '0x61',
                                        rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
                                    },
                                ],
                            });
                        } catch (addError) {
                            return {
                                address: "",
                                status: "1",
                            };
                        }
                    }
                }
            }

            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            })
            const obj = {
                status: "👆🏽 Write a message in the text-field above.",
                address: addressArray[0]
            };
            return obj;
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: "2",
        };
    }
}

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts"
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "👆🏽 Put BNB Amount in the text-field above.",
                }
            } else {
                return {
                    address: "",
                    status: "🦊 Connect to Metamask using the top right button.",
                }
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message
            }
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        🦊{" "}
                        <a target="_blank" href={`https://metamask.io/download.html`}>
                            You must install Metamask, a virtual Ethereum wallet, in your
                            browser.
                        </a>
                    </p>
                </span>
            ),
        };
    }
}

export const getTotalRaisedAmount = async (contract_address) => {
    let bnbAmount = await web3.eth.getBalance(contract_address && contract_address);
    bnbAmount = web3.utils.fromWei(bnbAmount, 'ether');
    return bnbAmount;
}

export const buyToken = async (bnbAmount, contract_address) => {

    const transactionParameters = {
        to: contract_address && contract_address, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's2. active address.

        'value': "0x" + Web3.utils.toBN(Web3.utils.toWei(bnbAmount, "ether")).toString(16),
        "gas": Number(21000).toString(16),
    };

    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: txHash
        }


    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }


}



// Sold: 1 dst   Remain: 1 dst