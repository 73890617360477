import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import "../src/css/brands.css"
import "../src/css/fontawesome.css"
import "../src/css/min-styles.css"
import "../src/css/solid.css"
// import "../src/css/magnific-popup.css"
import "../src/css/animate.css"
import "../src/css/basic.css"
import "../src/css/jarallax.css"
import "../src/css/layout.css"
import "../src/css/swiper.css"
import "../src/css/globals.css"
// import "../src/Admin/assets/css/all.css"
// import "../src/Admin/assets/css/Main.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./Redux/index"
import { composeWithDevTools } from "redux-devtools-extension";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandler from "./ErrorHandler";
import { CookiesProvider } from 'react-cookie';
// import "../src/js/min.scripts"
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
     