import React from 'react'
import DateTimeDisplay from './DateTimeDisplay';
import DateTimeDisplay1 from './DateTimeDisplay1';
import { useCountdown } from './useCountdown';
const ExpiredNotice = () => {
    return (
      <div className="expired-notice">
        <span>Expired!!!</span>
        <p>Please select a future date and time.</p>
      </div>
    );
  };

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="show-counter">
        <span
          target="_blank"
          rel="noopener noreferrer"
          className=""
          style={{paddingBottom:"32px",color:"#4bffa5"}}
        >
          {/* <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} /> */}
          <DateTimeDisplay1 value={days ? days :"00"} isDanger={false} />

          Days Left
        </span>
      </div>
    );
  };
const CountDownTimer1 = ({targetDate}) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
    //   return <ExpiredNotice />;
    } else {
      return (
        <ShowCounter
          days={days}
        
        />
      );
    }
 
}

export default CountDownTimer1