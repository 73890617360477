import { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected, buyToken, getTotalRaisedAmount } from "./utils/interact";
import tkn from "../images/tkn.svg"
import CountDownTimer from "./CountDownTimer";
import { ethers } from "ethers";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import web3 from "web3"
const contractABI = require('./presale.json')



const PreSale = () => {
    //State variables
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");
    const [transactionHash, setTransaction] = useState("");
    const [name, setName] = useState("");
    const [bnbAmount, setBNBAmount] = useState();
    const [tokenAmount, setTokenAmount] = useState();
    const [description, setDescription] = useState({});
    const [cap, setCap] = useState("");
    const [capTime, setCapTime] = useState("");
    const [capTimeCurrent, setCapTimeCurrent] = useState("");
    const [BuyStatus, setBuyStatus] = useState(true);
    const id = sessionStorage.getItem("userId")

    useEffect(() => { //TODO: implement
        async function data1() {
            if (id) {
                const { address, status } = await getCurrentWalletConnected();
                setWallet(address);
                addWalletListener();
            }
            else {
                setWallet("");
            }
        }
        data1()
    }, []);
    useEffect(() => {
        const FetchData = async () => {
            var response = await fetch(`/api/presale_all_data/1`);
            var res_data = await response.json();
            //usd currency rate api
            if (new Date(res_data.get_presale_data[0].soft_start_time) <= new Date(Date.now()) && new Date(res_data.get_presale_data[0].soft_end_time) >= new Date(Date.now())) {
                setCap("soft")
            }
            else if (new Date(res_data.get_presale_data[0].hard_start_time) <= new Date(Date.now()) && new Date(res_data.get_presale_data[0].hard_end_time) >= new Date(Date.now())) {
                setCap("hard")
            }
            else {
                setBuyStatus(false)
            }
            const diffTime = Math.abs(new Date(res_data.get_presale_data[0].soft_start_time) - new Date(res_data.get_presale_data[0].hard_end_time));
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setCapTime(diffDays)
            const diffTime1 = Math.abs(new Date(Date.now()) - new Date(res_data.get_presale_data[0].hard_end_time));
            const diffDays1 = Math.ceil(diffTime1 / (1000 * 60 * 60 * 24));
            setCapTimeCurrent(diffDays1)
            setDescription(res_data.get_presale_data[0])
        }
        FetchData()
    }, [])

   

    //connection successfully notify
    const notifyMess = (data) => {
        if (data.status === true) {
            toast.success(data.message);
        }
        else {
            toast.error(data.message);
            // console.log(data.message);
        }
    };

    const handleChange = (e) => {
        setBNBAmount(e.target.value)
        cap == "soft" ?
            setTokenAmount(e.target.value / description.soft_token_price) : setTokenAmount(e.target.value / description.hard_token_price)
    }
    const handleChange1 = (e) => {
        setTokenAmount(e.target.value)
        cap == "soft" ?
            setBNBAmount(description.soft_token_price * e.target.value) : setBNBAmount(description.hard_token_price * e.target.value)
    }
    // useEffect(() => {
    //     async function data2() {

    //         // let raisedAmount = await getTotalRaisedAmount(description.contract_address);
    //         // setRaisedBNB(raisedAmount);
    //         // console.log("raisedAmount =", raisedAmount);
    //     }
    //     data2()
    // }, [status])

    const connectWalletPressed = async () => {
        const { address, status } = await connectWallet();
        setWallet(address);
        const id = sessionStorage.getItem("id")
        const userlogin = {
            wallet_address: address,
            referral_id: id ? id : ""
        }
        if (status == 2) {
            notifyMess({ status: "false", message: "Please Add The Extension of MetaMask" })
        }
        if (status == 1) {
            notifyMess({ status: "false", message: "Please Add Binance Chain of Your Wallet" })
        }
        address && CallConnectMetamask(userlogin)

    }
    const CallConnectMetamask = async (userlogin) => {
        var response = await fetch("/api/user_wallet_conn", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(userlogin),
        });
        var res_data = await response.json();
        if (res_data) {
            sessionStorage.removeItem("id")
            sessionStorage.setItem("userId", res_data.user_id)
            notifyMess(res_data)
            window.location.reload()
        }
    }
    //Check Transaction Confirmed or Not 

    // Call Every 2 second and check transaction comfirmed or not 

    const onBuyPressed = async () => { //TODO: implement

        const { status, success } = await buyToken(bnbAmount ? bnbAmount : 0, description.wallet_address);
        setTransaction(status);
        sessionStorage.setItem("hash", status)

        if (success) {
            const data = {
                wallet_address: walletAddress,
                from_amount: bnbAmount,
                from_currency: "BUSD",
                from_rate: cap == "soft" ? description.soft_token_price : description.hard_token_price,
                to_amount: tokenAmount.toString(),
                to_currency: description.token_ticker,
                to_rate: cap == "soft" ? description.soft_token_price : description.hard_token_price,
                send_status: "1",
                received_status: "0",
                transaction_hash: status.toString()
            }
            var response = await fetch("/api/add_transaction", {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify(data),
            });
            var res_data = await response.json();

        }





    };

    const onEnterAmount = () => {
        notifyMess({ status: false, message: "Please Enter Amount" })
    }
    const addWalletListener = () => {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);

                    setStatus("👆🏽 Put BNB Amount in the text-field above.");
                } else {
                    setWallet("");
                    setStatus("🦊 Connect to Metamask using the top right button.");
                }
            })
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }

    const onFalseBuy = () => {
        notifyMess({ status: false, message: "Sale End" })
    }

    const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();

    const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;


    return (
        <>
            <ToastContainer />
            <div class="section1" id="section-presale">
                {/* <!-- Section About --> */}
                <div class="section about section-bg content"  >

                    {/* <div class="title">
                        <div class="title_inner">Pre Sale</div>
                    </div>
 */}

                    <div class="pricing-items2">
                        <div class="pricing-col2">
                            <div class="pricing-item content-box">
                                {/* <div class="icon"><span class="fas fa-star"></span></div> */}
                                <div class="name" style={{ color: "white", fontSize: "25px" }}>Sale Information</div>
                                <table style={{ margin: "0px", background: "transparent" }}>
                                    <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, .07)" }} className="text_align">
                                        <td className="tdRow1">Public Sale Start</td>
                                        <td className="tdRow2">{(new Date(description.soft_start_time) <= new Date(Date.now()) && new Date(description.soft_end_time) >= new Date(Date.now())) ? description.soft_start_time : description.hard_start_time}</td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, .07)" }} className="text_align">
                                        <td className="tdRow1">Public Sale End</td>
                                        <td className="tdRow2">{(new Date(description.soft_start_time) <= new Date(Date.now()) && new Date(description.soft_end_time) >= new Date(Date.now())) ? description.soft_end_time : description.hard_end_time}</td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, .07)" }} className="text_align">
                                        <td className="tdRow1">Total Token Supply</td>
                                        <td className="tdRow2">{description.total_token}</td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, .07)" }} className="text_align">
                                        <td className="tdRow1">Tokens Allocated for ICO</td>
                                        <td className="tdRow2">{description.total_token_ico}</td>
                                    </tr>

                                    <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, .07)" }} className="text_align">
                                        <td className="tdRow1">Hard Cap</td>
                                        <td className="tdRow2">${description.hard_token_price}</td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, .07)" }} className="text_align">
                                        <td className="tdRow1">Soft Cap</td>
                                        <td className="tdRow2">${description.soft_token_price}</td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, .07)" }} className="text_align">
                                        <td className="tdRow1">Token Starting Value</td>
                                        <td className="tdRow2">${description.token_starting_price}</td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, .07)" }} className="text_align">
                                        <td className="tdRow1">Token Value</td>
                                        <td className="tdRow2">${cap == "soft" ?
                                            description.soft_token_price : description.hard_token_price}</td>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, .07)" }} className="text_align">
                                        <td className="tdRow1">Accepted</td>
                                        <td className="tdRow2">BUSD </td>
                                    </tr>
                                </table>

                            </div>
                        </div>
                        <div class="pricing-col2">
                            <div class="pricing-item content-box presale-p" >
                                <div class="name" style={{ color: "white", fontSize: "25px" }}>Token Sale Stage End In</div>

                                <CountDownTimer targetDate={BuyStatus ? new Date(description.hard_end_time) : ""} />
                                <span class="progress-info" style={{ color: "#666" }}>Raised so far <span style={{ color: "#4bffa5" }}>${(description.total_sold_token * 0.03525).toFixed(2)}</span></span>
                                <div style={{ color: "#666" }}><span style={{ float: "left" }}>Sold: <span style={{ color: "#4bffa5" }}>{description.total_sold_token} {description.token_ticker}</span></span>
                                    <span style={{ float: "right" }}>Remain: <span style={{ color: "#4bffa5" }}>{description.total_token_ico - description.total_sold_token} {description.token_ticker}</span></span></div>

                                <div class="skills percent content-box" style={{ border: "none", padding: "0px" }}>
                                    <div class="vr">&nbsp;</div>
                                    <div class="vr1">&nbsp;</div>

                                    <ul>
                                        <li>
                                            <div class="progress " style={{ position: "initial", height: "38px", borderRadius: "0px" }}>
                                                <div class="percentage" style={{ width: `${description.total_sold_token * 100 / description.total_token_ico}%`, height: "40%", background: "linear-gradient(to right, #4bffa5,#4bffa5e8,#4bffa5c9,#4bffa585)", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>

                                                </div>
                                                <span class="percent1">SOFT CAP</span>
                                                <span class="percent2">HARD CAP</span>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div className="row presale-m" >

                                    <div className="col-5">
                                        {/* smtp host */}
                                        <div className="admin-input-spacing " style={{ border: "none" }}>
                                            {/* <label htmlFor="smtp_host" className="form-label text-white">
                                                BUSD
                                            </label> */}
                                            <input
                                                type="number"
                                                min="1"
                                                className="form-control1 theme-input mt-4"
                                                id="smtp_host"
                                                placeholder="BUSD"
                                                name="token_name"
                                                value={bnbAmount}
                                                style={{ color: "white" }}
                                                onChange={handleChange}

                                            />
                                        </div>
                                        {/* smtp port */}


                                    </div>
                                    <div className="col-2">

                                        <i class='fas fa-exchange-alt text-white' style={{ paddingTop: "58px" }}></i>
                                    </div>
                                    <div className="col-5">
                                        {/* smtp pass */}
                                        <div className="admin-input-spacing" style={{ border: "none" }}>
                                            {/* <label htmlFor="smtp_user" className="form-label text-white">
                                                TKN
                                            </label> */}
                                            <input
                                                type="number"
                                                className="form-control1 theme-input mt-4"
                                                id="token_starting_price"
                                                placeholder={description.token_ticker}
                                                name="token_starting_price"
                                                value={tokenAmount}
                                                style={{ color: "white" }}
                                                onChange={handleChange1}

                                            />
                                        </div>


                                    </div>
                                </div>
                                <div class="bts" style={{ marginTop: "63px" }}><a href="#" class="btn hover-animated" onClick={walletAddress.length > 0 ? BuyStatus ? bnbAmount ? onBuyPressed : onEnterAmount : onFalseBuy : connectWalletPressed}>
                                    <span class="circle">
                                        <span class="ink">
                                        </span>
                                    </span>
                                    {walletAddress.length > 0 ?
                                        <span class="lnk"> BUY TOKEN NOW</span>
                                        :
                                        <span class="lnk">Connect to Wallet</span>}
                                </a>
                                </div>
                                <span style={{ color: "#666", wordBreak: "break-all" }}>Min.Purchase: ${cap == "soft" ? description.soft_min_purchase : description.hard_min_purchaes}</span>
                                <span style={{ color: "#666", paddingLeft: "20px" }}>Max.Purchase: ${cap == "soft" ? description.soft_max_purchase : description.hard_max_purchaes}</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

















        </>

    )
}

export default PreSale


