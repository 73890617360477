

import Layout from "../layout/Layout";
import $ from "jquery"
import PreSale from "../components/PreSale";
import Error from "../components/Error";
import { useEffect } from "react";
// import Presale2 from "./Presale2";
const PreSaleComponent = () => {
  // Cache selectors
//   var lastId,
//     topMenu = $("#top-menu"),
//     topMenuHeight = topMenu.outerHeight() + 15,
//     // All list items
//     menuItems = topMenu.find("a"),
//     // Anchors corresponding to menu items
//     scrollItems = menuItems.map(function () {
//       var item = $($(this).attr("href"));
//       if (item.length) { return item; }
//     });

//   // Bind click handler to menu items
//   // so we can get a fancy scroll animation
//   menuItems.click(function (e) {
//     var href = $(this).attr("href"),
//       offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
//     $('html, body').stop().animate({
//       scrollTop: offsetTop
//     }, 300);
//     e.preventDefault();
//   });

//   // Bind to scroll
//   $(window).scroll(function () {
//     // Get container scroll position
//     var fromTop = $(this).scrollTop() + topMenuHeight;

//     // Get id of current scroll item
//     var cur = scrollItems.map(function () {
//       if ($(this).offset().top < fromTop)
//         return this;
//     });
//     // Get the id of the current element
//     cur = cur[cur.length - 1];
//     var id = cur && cur.length ? cur[0].id : "";

//     if (lastId !== id) {
//       lastId = id;
//       // Set/remove active class
//       menuItems
//         .parent().removeClass("active1")
//         .end().filter("[href='#" + id + "']").parent().addClass("active1");
//     }
//   });





useEffect(()=>{
  console.clear();
},[])
  return (
    <>
    <Layout>
     

      {/* <About />
      <Porfolio />
      <Contact /> */}
      <PreSale />
      {/* <Error /> */}
    </Layout>
    </>
  );
};
export default PreSaleComponent;
