
import {
  BrowserRouter as Router,

} from "react-router-dom";
import React, { Component, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "jquery/dist/jquery.js"
// import "../src/css/vendor/icons.min.css"
import MainRoute from './Routes/MainRoute';
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import contractAbi from "../src/components/presale.json";
const Web3 = require('web3');
const web3 = new Web3();
function App() {
  $(document).ready(function () {
    // error throw
    $(window).on("load", function () {
      setTimeout(function () {
        $('.theme_panel .toggle_bts a.sett').removeClass('active');
        $('.theme_panel').removeClass('active');
      }, 3000);
    });
  });


  //connection successfully notify
  const notifyMess = (data) => {
    if (data.status === true) {
      toast.success(data.message);
    }
    else {
      toast.error(data.message);
      // console.log(data.message);
    }
  };
  const send_token = async (hash) => {

    var response2 = await fetch(`/api/get_transaction/${hash}`);
    var res_data2 = await response2.json();

    const res_user = res_data2.get_user_data[0]

    $.ajax({
      url: '/api/token/transfer.php',
      type: 'post',
      data: { "wallet_address": res_user.wallet_address, "amount": res_user.to_amount, transaction_hash: hash },
      success: function (response) { 
        }
    });


  }
  const CronFun = async (txthash) => {
    const txHash = await window.ethereum
      .request({
        method: 'eth_getTransactionReceipt',
        params: [txthash],
        "id": 1, "jsonrpc": "2.0"
      });

    if (txHash.status && txHash.status == '0x1') {

      const api_call = async () => {

        const data1 = { send_status: "1" }
        var response = await fetch(`/api/update_transaction_status/${txthash}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data1),
        });
        var res_data1 = await response.json();
        notifyMess({ status: true, message: "Transaction Send Successfully" })
        sessionStorage.removeItem("hash")
        send_token(txthash)

      }
      api_call()
    }
    else if (txHash.status && txHash.status == '0x0') {
      const api_call1 = async () => {

        const data1 = { send_status: "2" }
        var response = await fetch(`/api/update_transaction_status/${txthash}`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data1),
        });
        var res_data1 = await response.json();
        notifyMess({ status: false, message: "Transaction Not  Send Successfully" })
        sessionStorage.removeItem("hash")

      }
      api_call1()
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const hash = sessionStorage.getItem("hash")
      hash && CronFun(hash)

    }, 2000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])
   
useEffect(()=>{
 console.clear()
},[])
  

  return (
    <>
      <ToastContainer />
      <Router>
        {/* Main Routing */}
        <MainRoute />

      </Router>
    </>
  );
}

export default App;
