import { useLocation, NavLink } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link"
import { Link } from "react-scroll";
import coin_logo from "../images/dStoragelogo.png"
import { connectWallet, getCurrentWalletConnected } from "../components/utils/interact";
import logo from "../images/Avatar02.png"
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Navbar, Dropdown, Button, Form, Col, Row, Modal } from "react-bootstrap";
import güncel from "../images/dStorageWhitepaper.pdf"

const Menu = () => {
  const [walletAddress, setWallet] = useState("");

  //connection successfully notify
  const notifyMess = (data) => {
    if (data.status === true) {
      toast.success(data.message);
    }
    else {
      toast.error(data.message);
      // console.log(data.message);
    }
  };
  const handleLogout = () => {
    sessionStorage.removeItem("userId")
    setWallet("")
    window.location.href = "/"
  }
  const id = sessionStorage.getItem("userId")
  const location = useLocation()
  // console.log(location);
  const handleClick = async () => {
    const { address, status } = await connectWallet();
    setWallet(address);
    const id = sessionStorage.getItem("id")
    const userlogin = {
      wallet_address: address,
      referral_id: id ? id : ""
    }
    if(status == 2){
      notifyMess({status:"false",message:"Please Add The Extension of MetaMask"})
    }
    if(status == 1){
      notifyMess({status:"false",message:"Please Add Binance Chain of Your Wallet"})
    }
    address && CallConnectMetamask(userlogin)
  
  }
  const CallConnectMetamask=async(userlogin)=>{
    var response = await fetch("/api/user_wallet_conn", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(userlogin),
    });
    var res_data = await response.json();
    if (res_data) {
      sessionStorage.removeItem("id")
      sessionStorage.setItem("userId", res_data.user_id)

      notifyMess(res_data)
    }
  }
  useEffect(() => { //TODO: implement
    async function data1() {
      if (id) {

        const { address, status } = await getCurrentWalletConnected();
        setWallet(address);

        addWalletListener();
      }
      else {
        setWallet("");

      }
    }
    data1()
  }, []);
  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);

          // setStatus("👆🏽 Put BNB Amount in the text-field above.");
        } else {
          setWallet("");
          // setStatus("🦊 Connect to Metamask using the top right button.");
        }
      })
    } else {
      // setStatus(
      //     <p>
      //         {" "}
      //         🦊{" "}
      //         <a target="_blank" href={`https://metamask.io/download.html`}>
      //             You must install Metamask, a virtual Ethereum wallet, in your
      //             browser.
      //         </a>
      //     </p>
      // );
    }
  }
  return (

    <Fragment>
      {/* <ToastContainer/> */}
      <div className="logo">
        <NavLink to="/">
          {/* <span className="mask-lnk">
          Alejandro <strong>Abeyta</strong>
        </span>
        <span className="mask-lnk mask-lnk-hover">
          Download <strong>CV</strong>
        </span> */}

          <img src={coin_logo} width="50%"/>
        </NavLink>
      </div>
      {/* top menu */}
      <div className="top-menu hover-masks">
        <div className="top-menu-nav">
          <div className="menu-topmenu-container">
            <ul className="menu" id="top-menu">
              <li className="menu-item ">
                {/* <Link href="#section-started"> */}
                <NavLink to="/" className={`${location.pathname}` === "/" ? "active1" : ""}>

                  {/* <HashLink to="#section-started" className={`${location.hash}` === "#section-started"  ? "active1" : ""}> */}
                  <span className="mask-lnk">Home</span>
                  <span className="mask-lnk mask-lnk-hover">Home</span>
                  {/* </HashLink> */}
                </NavLink>

              </li>
              <li className="menu-item ">
                {/* <Link href="#section-started"> */}
                <NavLink to="/announcement" className={`${location.pathname}` === "/announcement" ? "active1" : ""}>

                  {/* <HashLink to="#section-started" className={`${location.hash}` === "#section-started"  ? "active1" : ""}> */}
                  <span className="mask-lnk">Announcement</span>
                  <span className="mask-lnk mask-lnk-hover">Announcement</span>
                  {/* </HashLink> */}
                </NavLink>

              </li>
              <li className="menu-item ">
                {/* <Link href="#section-started"> */}

                
                <a href={güncel} target="_blank"  data-title="dStoragewhitepaper">

                  {/* <HashLink to="#section-started" className={`${location.hash}` === "#section-started"  ? "active1" : ""}> */}
                  <span className="mask-lnk">Whitepaper</span>
                  <span className="mask-lnk mask-lnk-hover">Whitepaper</span>
                  {/* </HashLink> */}
                </a>

              </li>
              <li className="menu-item ">
                {/* <Link href="#section-started"> */}
                <NavLink to="/partner" className={`${location.pathname}` === "/partner" ? "active1" : ""}>

                  {/* <HashLink to="#section-started" className={`${location.hash}` === "#section-started"  ? "active1" : ""}> */}
                  <span className="mask-lnk">Partner</span>
                  <span className="mask-lnk mask-lnk-hover">Partner</span>
                  {/* </HashLink> */}
                </NavLink>

              </li>
              <li className="menu-item ">
                {/* <Link href="/resume"> */}
                <NavLink to="/airdrop" className={`${location.pathname}` === "/airdrop" ? "active1" : ""}>


                  {/* <HashLink to="#section-about" className={`${location.hash}` === "#section-about" ? "active1" : ""}> */}
                  <span className="mask-lnk">Airdrop</span>
                  <span className="mask-lnk mask-lnk-hover">Airdrop</span>
                  {/* </HashLink> */}
                </NavLink>

              </li>


              <li className="menu-item ">
                {/* <Link href="/contacts"> */}
                <NavLink to="/presale" className={`${location.pathname}` === "/presale" ? "active1" : ""}>

                  {/* <HashLink to="#section-presale" className={`${location.hash}` === "#section-presale" ? "active1" : ""}> */}
                  <span className="mask-lnk">Pre Sale</span>
                  <span className="mask-lnk mask-lnk-hover">Pre Sale</span>
                  {/* </HashLink> */}
                </NavLink>

              </li>

              <li className="menu-item " style={{marginLeft:`${walletAddress&& "-9px" }`}} >

                {walletAddress ?
                  // <div className="active-login">
                  //   <span className="mask-lnk">{status.substring(0, 5) + "..."}
                  //     <img src={logo} width="20%" />

                  //   </span>
                  //   <span className="mask-lnk mask-lnk-hover">{status.substring(0, 5) + "..."}</span>
                  // </div> 

                  <Dropdown className=" main-profile-menu nav nav-item nav-link ps-lg-2">

                    <Dropdown.Toggle
                      className="new nav-link profile-user d-flex b-none"
                      variant=""
                      style={{marginLeft:"39%"}}
                    >
                      <NavLink >
                        <span className="mask-lnk " style={{marginTop:"-6px"}}>
                          <span style={{ color: "#666", paddingRight: "10px" }} >{walletAddress.substring(0, 5) + "..."}</span>
                          <img src={logo} width="20%" />
                        </span>
                        <span className="mask-lnk mask-lnk-hover" style={{marginTop:"-6px"}}>
                          <span style={{ color: "#666", paddingRight: "10px" }} >{walletAddress.substring(0, 5) + "..."}</span>
                          <img src={logo} width="20%" />
                        </span>
                      </NavLink>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/*  */}
                      <Dropdown.Item className="dropdown-item text-dark"
                        href={`/profile`}
                      >
                        <i className="far fa-user-circle me-3"></i>Profile
                      </Dropdown.Item>

                      <Dropdown.Item
                        className="dropdown-item text-dark"
                        onClick={handleLogout}
                      // href={`${process.env.PUBLIC_URL}/pages/mail/readmail`}
                      >
                        <i className="far fa-arrow-alt-circle-left me-3"></i>Logout
                      </Dropdown.Item>

                    </Dropdown.Menu>
                  </Dropdown>
                  :

                  <NavLink onClick={handleClick} >

                    <span className="mask-lnk">Connect to Wallet</span>
                    <span className="mask-lnk mask-lnk-hover">Connect to Wallet</span>
                    {/* </HashLink> */}
                  </NavLink>
                }
              </li>

            </ul>
          </div>
        </div>
      </div>
    </Fragment >
  );
}

const Header = () => {
  return (
    <Fragment>
      <MobileHeader />
      <DeskTopHeader />
    </Fragment>
  );
};
export default Header;

const DeskTopHeader = () => (
  <header className={`header desktopHeader`}>
    <div className="head-top">
      <Menu />
    </div>
  </header>
);

const MobileHeader = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <header className={`header mobileHeader ${toggle ? "active" : ""}`}>
      <div className="head-top">
        {/* menu button */}
        <a href="#" className="menu-btn" onClick={() => setToggle(!toggle)}>
          <span />
        </a>
        {/* logo */}
        <Menu />
      </div>
    </header>
  );
};
