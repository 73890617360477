import React from 'react'

const CheckTweet = async(data, users) => {
 
    var response = await fetch("/api/get_airdrop_data", {
        method: "GET",
        
    });
    var res_data = await response.json();
    const user = await fetch(`/api/tweet/retweetby/${res_data.airdrop_data[0].twitter_share}`)
    const res_user = await user.json()
    if (res_user.data) {
        const found = res_user.data.some(el => el.id === users)
        if (found) {
            return { status: true }
        }
    }
}

export default CheckTweet