import React from 'react'

const CheckFollow = async (data) => {
    var response = await fetch("/api/get_airdrop_data", {
        method: "GET",
        
    });
    var res_data = await response.json();
    const user = await fetch(`/api/user/following/${data}`)
    const res_user = await user.json()
    if (res_user.data) {
        const found = res_user.data.some(el => el.username == res_data.airdrop_data[0].twitter_follow_account)
        if (found) {
            
            return { status: true }
        }
    }
}

export default CheckFollow