import React from 'react'
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';
const ExpiredNotice = () => {
    return (
      <div className="expired-notice">
        <span>Expired!!!</span>
        <p>Please select a future date and time.</p>
      </div>
    );
  };

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="show-counter">
        <span
          target="_blank"
          rel="noopener noreferrer"
          className="countdown-link"
          style={{paddingBottom:"32px"}}
        >
          {/* <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} /> */}
          <DateTimeDisplay value={days ? days :"00"} type={'Days'} isDanger={false} />

          <p>:</p>
          <DateTimeDisplay value={hours? hours:"00"} type={'Hou.'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes?minutes:"00"} type={'Min.'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds?seconds:"00"} type={'Sec.'} isDanger={false} />
        </span>
      </div>
    );
  };
const CountDownTimer = ({targetDate}) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
    //   return <ExpiredNotice />;
    } else {
      return (
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      );
    }
 
}

export default CountDownTimer