import About from "../components/About";
import Contact from "../components/Contact";
import Porfolio from "../components/Porfolio";
import TypingAnimation from "../components/TypingAnimation";
import Layout from "../layout/Layout";
import $ from "jquery"
import PreSale from "../components/PreSale";
import Error from "../components/Error";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
// import videoBg from "../images/VideoSon.mp4"
const Index = () => {

  var location = useLocation()
  var params = useParams()
  const queryParams = new URLSearchParams(location.search)
  const term = queryParams.get("ref")
  location = {

    pathname: "/",

    hash: "#section-started"
  }
  useEffect(() => {
    if (term) {
      sessionStorage.setItem("id", term)
    }
  }, [])

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [location,])

  useEffect(() => {
    let { jarallax, jarallaxVideo } = require("jarallax");
    jarallaxVideo();
    jarallax(document.querySelectorAll(".jarallax-video"), {
      speed: 0.5,
      keepImg: true,
      automaticResize: true,
      videoVolume: 0,

    });
  }, []);
  return (
    <Layout>
      <ToastContainer />
      <div className="section started" id="section-started">
        {/* background */}
        <div
          id="started-video-bg"
          className="video-bg media-bg jarallax-video video-mobile-bg"
          data-jarallax-video="https://www.youtube.com/watch?v=bsR4TVhtkYo"
          data-mobile-preview="images/started_image_p.jpg"
          data-volume={100}
        >
          <div className="video-bg-mask" />
          <div className="video-bg-texture" id="grained_container" />
        </div>

        {/* <video autoPlay muted loop id="myVideo">
          <source src={videoBg} type="video/mp4" />
        </video> */}

        {/* started content */}
        <div className="centrize full-width">
          <div className="vertical-center">
            <div className="started-content">
              <h1 className="h-title">

                <strong>dStorage</strong> (dSt) token is built on the Binance Smart Chain. Our token allows to record data over the network and use the dStorage platform.
                {/* Hello, I’m <strong>Alejandro Abeyta</strong>, UX/UI Designer and{" "}
                <br />
                Front-end Developer Based in San Francisco. */}
              </h1>
              {/* <TypingAnimation extraClassName={"typed-subtitle"} /> */}
              <span className="typed-subtitle" />
            </div>
          </div>
        </div>
      </div>

      {/* <About />
      <Porfolio />
      <Contact />
      <Error /> */}
    </Layout>
  );
};
export default Index;
