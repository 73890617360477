import {React,useState,useEffect} from 'react'
import Layout from '../layout/Layout'
import parse from 'html-react-parser';

const Announcement = () => {
  const [data, setData] = useState({
    announcement:""
  })

  //get the setting data on page load
  useEffect(() => {
    const data1 = async () => {
      var response = await fetch(`/api/setting_data`);
      var res_data = await response.json();
      setData(res_data)
    }
    data1()
  }, []);
  return (
    <Layout>
      <div className="section started" id="section-started">
        {/* background */}
        <div class="section  section-bg" style={{paddingTop:"14%"}} >

          {/* <!-- title --> */}
          <div class="title">
            <div class="title_inner">Announcement</div>
          </div>

          <div class="content content-box content-bg">
            {/* <!-- desc --> */}
            <div class="">
              <p style={{ color: "#bbb" }}>{parse(data &&data['announcement'])}</p>
            </div>

            <div class="clear"></div>
          </div>
        </div>



      </div>

      {/* <About />
<Porfolio />
<Contact />
<Error /> */}
    </Layout>
  )
}

export default Announcement