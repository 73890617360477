const Footer = () => {
  return (
    <footer className="footer">
      <div className="copy">
      E:<a href="https://admin@dstorage.pro" target="_blank" style={{textDecoration:"none", color:"#666"}}> admin@dstorage.pro</a>
        <p>T:<a href="https://t.me/dStoragepro" target="_blank" style={{textDecoration:"none", color:"#666"}}> t.me/dStoragepro</a></p>
      </div>
      <div className="soc-box">
        <div className="follow-label">Follow Me</div>
        <div className="soc">
          <a target="_blank" rel="noreferrer" href="https://t.me/dStorageproTr/">
            <span className="icon fab fa-telegram" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://t.me/dStorageproANN/">
            <span className="icon fab fa-telegram" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/dStoragepro">
            <span className="icon fab fa-twitter" />
          </a>
        </div>
      </div>
      <div className="clear" />
    </footer>
  );
};
export default Footer;
