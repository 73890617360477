import { React, useState, useEffect } from 'react'
import digipharmgiveaway from "../images/digipharmgiveaway.png"
import twitter from "../images/twitter.png"
// import { TelegramClient } from "telegram";
// import { StringSession } from "telegram/sessions";
import metamask from "../images/metamask.svg"
import { Tooltip as ReactTooltip } from 'react-tooltip'
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Layout from '../layout/Layout'
import telegram from "../images/telegram.png"
import { Link, useNavigate } from 'react-router-dom'
import parse from 'html-react-parser';
import { connectWallet, getCurrentWalletConnected } from './utils/interact'
import CountDownTimer1 from './CountDownTimer1';

import CheckFollow from './airdropStep/CheckFollow'
import CheckTweet from './airdropStep/CheckTweet'
import CheckLike from './airdropStep/CheckLike'

const AirDrop = () => {
  const [state, setState] = useState({
    step1: "0",
    step2: "0",
    step3: "0",
    step4: "0"
  })
  const [Completestate, setComplateState] = useState({
    step1: "0",
    step2: "0",
    step3: "0",
    step4: "0"
  })
  const [stateToken, setStatetoken] = useState("")
  const [followstatus, setFollowstatus] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false
  })

  const [stateTokenScre, setStatetokenScre] = useState("")

  const [html, setHTML] = useState({ __html: "" });
  const [dataStatus, setDataStatus] = useState(
    {
      step1: false,
      step2: false,
      step3: false,
      step4: false
    }
  )
  const [loginStatus, setLoginStatus] = useState(
    {
      step1: false,
      step2: false,
      step3: false,
      step4: false
    }
  )
  const [tooltipStatus, SetTooltipStatus] = useState(
    {
      step1: false,
      step2: false,
      step3: false,
      step4: false
    }
  )
  const [timeOut, setTimeout] = useState(false)
  const [cred, setcred] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false
  })
  const [own, setOwn] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false
  })

  const [user, setUser] = useState({
    airdrop_description: ""
  })
  const [wallet, setWallet] = useState("")
  const navigate = useNavigate()

  //get the airdrop data on page load
  useEffect(() => {
    const apiData = async () => {

      var response = await fetch("/api/get_airdrop_data", {
        method: "GET",

      });
      var res_data = await response.json();
      setUser(res_data.airdrop_data[0])
      if (new Date(res_data.airdrop_data[0].airdrop_end_date) < new Date(Date.now())) {
        setTimeout(true)
      }
    }
    apiData()
    apigetData()
  }, []);
  //connection successfully notify
  const notifyMess = (data) => {
    if (data.status === true) {
      toast.success(data.message);
    }
    else {
      toast.error(data.message);
      // console.log(data.message);
    }
  };
  const id = sessionStorage.getItem("userId")

  useEffect(() => { //TODO: implement
    async function data1() {
      if (id) {

        const { address, status } = await getCurrentWalletConnected();
        setWallet(address);
        setLoginStatus(
          {
            step1: false,
            step2: false,
            step3: false,
            step4: false
          }

        )
      }
      else {
        setWallet("");

      }
    }
    data1()
  }, []);


  // Login With MetaMask 
  const handleLogin = async () => {
    const { address, status } = await connectWallet();
    setWallet(address);
    const id = sessionStorage.getItem("id")
    const userlogin = {
      wallet_address: address,
      referral_id: id ? id : ""
    }
    if (status == 2) {
      notifyMess({ status: "false", message: "Please Add The Extension of MetaMask" })
    }
    if (status == 1) {
      notifyMess({ status: "false", message: "Please Add Binance Chain of Your Wallet" })
    }
    address && CallConnectMetamask(userlogin)

  }
  const CallConnectMetamask = async (userlogin) => {
    var response = await fetch("/api/user_wallet_conn", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(userlogin),
    });
    var res_data = await response.json();
    if (res_data) {
      sessionStorage.removeItem("id")
      sessionStorage.setItem("userId", res_data.user_id)
      notifyMess(res_data)
      setLoginStatus({
        step1: false,
        step2: false,
        step3: false,
        step4: false
      })

      window.location.reload()
    }
  }








  //Logout Account
  const handleLogout = () => {
    sessionStorage.removeItem("userId")
    setWallet("")
    window.location.href = "/"
  }
  var dataId = sessionStorage.getItem("userId")

  const stepUpdate = async (step) => {
    const data = { airdrop_step: step, airdrop_status: "1" }
    var response = await fetch(`/api/update_airdrop_step_data/${dataId}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res_data = await response.json();
  }
  const getStepData = async () => {
    var response = await fetch(`/api/get_airdrop_step/${dataId}`);
    var res_data = await response.json();

    setState({
      ...state,
      step1: res_data.get_user_data[0] && res_data.get_user_data[0].follow_twitter,
      step2: res_data.get_user_data[0] && res_data.get_user_data[0].like_twitter,
      step3: res_data.get_user_data[0] && res_data.get_user_data[0].share_twitter,
      step4: res_data.get_user_data[0] && res_data.get_user_data[0].join_telegram
    })
    setComplateState({
      ...Completestate,
      step1: res_data.get_user_data[0] && res_data.get_user_data[0].follow_twitter,
      step2: res_data.get_user_data[0] && res_data.get_user_data[0].like_twitter,
      step3: res_data.get_user_data[0] && res_data.get_user_data[0].share_twitter,
      step4: res_data.get_user_data[0] && res_data.get_user_data[0].join_telegram
    })

  }
  useEffect(() => {
    getStepData()
  }, [])
  const getCredinalData = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.has('oauth_token') && params.has('oauth_verifier')) {
      let oauth_token = params.get('oauth_token');
      let oauth_verifier = params.get('oauth_verifier');
      if (oauth_token && oauth_verifier) {



        var requestOptions = {
          method: 'POST',

          redirect: 'follow'
        };

        const data = await fetch(`/api/access_token/${oauth_verifier}/${oauth_token}`, requestOptions)
        const res_data = await data.text()
        const dta = res_data.split('&')
        // setState(dta[0])
        const dta1 = dta[0].split('=')
        setStatetoken(dta1[1])
        const dta2 = dta[1].split('=')
        setStatetokenScre(dta2[1])
        const dta3 = dta[2].split('=')
        sessionStorage.setItem("user", dta3[1])
        sessionStorage.setItem("usertoken", dta1[1])
        sessionStorage.setItem("userscreat", dta2[1])

        // navigate("/airdrop")
      }
    }
  }
  useEffect(() => {
    getCredinalData()
  }, [])

  const handleCredinal = async () => {

    const data = await fetch("/api/request_token")
    const res_data = await data.text()
    const dta = res_data.split('&')
    // setState(dta[0])
    const dta1 = dta[0].split('=')
    window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${dta1[1]}`
  }



  const userToken = sessionStorage.getItem("user")
  const userToken1 = sessionStorage.getItem("usertoken")
  const userscreat = sessionStorage.getItem("userscreat")


  //handle Step
  const handleStep = (value) => {
    if (wallet.length) {
      if (user.airdrop_status == "1") {


      }
      else {
        if (value == "1") {
          setDataStatus({
            ...dataStatus,
            step1: !dataStatus.step1
          })
          if (userToken) {
            if (state.step1 == "0") {
              setOwn({
                step1: true,
                step2: false,
                step3: false,
                step4: false

              })

              //follow twitter 
              //Status Update
              // var status = followTwitter(userToken1, userscreat, user && user.twitter_follow_account, user && user.twitter_api_key, user && user.twitter_secret_key, userToken)
              // if (status) {
              //   stepUpdate("follow_twitter")
              // }
            }
          }
          else {
            // handleCredinal()
            setcred({
              step1: true,
              step2: false,
              step3: false,
              step4: false

            })
          }
        }
        else if (value == "2") {
          setDataStatus({
            ...dataStatus,
            step2: !dataStatus.step2
          })
          if (userToken) {

            if (state.step1 == "1" && state.step2 == "0") {
              //like twitter 
              //Status Update
              setOwn({
                step1: false,
                step2: true,
                step3: false,
                step4: false

              })

              // var status = likePost(userToken1, userscreat, user && user.twitter_follow_account, user && user.twitter_api_key, user && user.twitter_secret_key, userToken)
              // if (status) {
              //   stepUpdate("like_twitter")

              // }
            }
            else if (state.step1 == "0") {
              //tooltip
              SetTooltipStatus({
                step1: false,
                step2: true,
                step3: false,
                step4: false
              })
            }
            else {
              //Task is Completed
            }
          } else {
            setcred({
              step1: false,
              step2: true,
              step3: false,
              step4: false

            })
          }

        }
        else if (value == "3") {
          setDataStatus({
            ...dataStatus,
            step3: !dataStatus.step3
          })
          if (userToken) {

            if (state.step1 == "1" && state.step2 == "1" && state.step3 == "0") {
              //share twitter 
              //Status Update
              setOwn({
                step1: false,
                step2: false,
                step3: true,
                step4: false

              })
              // var status = SharePost(userToken1, userscreat, user && user.twitter_follow_account, user && user.twitter_api_key, user && user.twitter_secret_key, userToken)
              // if (status) {
              //   stepUpdate("share_twitter")
              // }
            }
            else if (state.step2 == "0") {
              //tooltip
              SetTooltipStatus({
                step1: false,
                step2: false,
                step3: true,
                step4: false
              })
            }
            else {
              //Task is Completed
            }
          } else {
            setcred({
              step1: false,
              step2: false,
              step3: true,
              step4: false
            })
          }
        }
        else {
          setDataStatus({
            ...dataStatus,
            step4: !dataStatus.step4
          })

          if (state.step1 == "1" && state.step2 == "1" && state.step3 == "1" && state.step4 == "0") {
            //Join telegram 
            //Status Update
            setOwn({
              step1: false,
              step2: false,
              step3: false,
              step4: true

            })
            // stepUpdate("join_telegram")
          }
          else if (state.step3 == "0") {
            //tooltip
            SetTooltipStatus({
              step1: false,
              step2: false,
              step3: false,
              step4: true
            })

          }
          else {
            //Task is Completed
          }
        }
      }
    }
    else {

      if (value == "1") {
        setDataStatus({
          ...dataStatus,
          step1: !dataStatus.step1
        })
        setLoginStatus({
          step1: true,
          step2: false,
          step3: false,
          step4: false
        })
      }
      else if (value == "2") {
        setDataStatus({
          ...dataStatus,
          step2: !dataStatus.step2
        })
        setLoginStatus({
          step1: false,
          step2: true,
          step3: false,
          step4: false
        })
      }
      else if (value == "3") {
        setDataStatus({
          ...dataStatus,
          step3: !dataStatus.step3
        })
        setLoginStatus({
          step1: false,
          step2: false,
          step3: true,
          step4: false
        })
      }
      else {
        setDataStatus({
          ...dataStatus,
          step4: !dataStatus.step4
        })
        setLoginStatus({
          step1: false,
          step2: false,
          step3: false,
          step4: true
        })
      }
    }
  }

  const apigetData = async () => {
    const data = sessionStorage.getItem("user_follow")
    const data1 = sessionStorage.getItem("user_Like")
    const data2 = sessionStorage.getItem("user_Post")
    const data3 = sessionStorage.getItem("user_Join")


    if (data1) {
      const { status } = await CheckLike(data1)

      if (status) {

        setComplateState({
          ...Completestate,
          step1: "1",
          step2: "1",

        })

        setState({
          ...state,
          step1: "1",
          step2: "1",

        })

        sessionStorage.removeItem("user_Like")

        stepUpdate("like_twitter")
      }
    }
    else if (data) {
      const { status } = await CheckFollow(data, user && user.twitter_follow_account)
      if (status) {

        setComplateState({
          ...Completestate,

          step1: "1",

        })
        setState({
          ...state,
          step1: "1",


        })
        sessionStorage.removeItem("user_follow")

        stepUpdate("follow_twitter")
      }
    }
    else if (data2) {
      const { status } = await CheckTweet(user && user.twitter_share, data2)
      if (status) {

        setComplateState({
          ...Completestate,
          step1: "1",
          step2: "1",
          step3: "1",

        })
        setState({
          ...state,
          step1: "1",
          step2: "1",
          step3: "1"
        })
        sessionStorage.removeItem("user_Post")
        stepUpdate("share_twitter")
      }
    }
    else if (data3) {
      setComplateState({
        ...Completestate,
        step1: "1",
        step2: "1",
        step3: "1",
        step4: "1",

      })
      setState({
        ...state,
        step1: "1",
        step2: "1",
        step3: "1",
        step4: "1",
      })
      sessionStorage.removeItem("user_Join")

      stepUpdate("join_telegram")
    }
  }

  const handleFollow = () => {
    sessionStorage.setItem("user_follow", userToken)
    setFollowstatus({
      step1: true,
      step2: false,
      step3: false,
      step4: false
    })
    window.location.href = `https://twitter.com/intent/follow?screen_name=${user && user.twitter_follow_account}`
  }
  const handleLike = () => {
    sessionStorage.setItem("user_Like", userToken)
    setFollowstatus({
      step1: false,
      step2: true,
      step3: false,
      step4: false    
    })
    window.location.href = `https://twitter.com/intent/like?tweet_id=${user && user.twitter_like}`
  }
  const handlePost = () => {
    sessionStorage.setItem("user_Post", userToken)
    setFollowstatus({
      step1: false,
      step2: false,
      step3: true,
      step4: false
    })
    window.location.href = ` https://twitter.com/intent/retweet?tweet_id=${user && user.twitter_share}`

  }
  const handleJoin = () => {
    sessionStorage.setItem("user_Join", userToken)
    setFollowstatus({
      step1: false,
      step2: false,
      step3: false,
      step4: true
    })
    window.location.href = ` ${user && user.telegram_join_channel}`

  }

  return (
    <>
      {user.airdrop_status == "1" ?
        navigate("/") :

        <Layout>

          <div class="section skills airdrop-page" id="section-skills" >
            <div class="content">

              {/* <!-- title --> */}
              {/* <div class="title">
            <div class="title_inner">Design Skills</div>
          </div> */}
              {/* <!-- skills items --> */}
              <div class="skills percent content-box">
                {
                  timeOut ?
                    <h1 style={{ borderBottom: "1px solid #333", color: "#666", textAlign: "center" }}>
                      Ended </h1> :
                    <h1 style={{ borderBottom: "1px solid #333", color: "#666", textAlign: "center" }}>
                      <CountDownTimer1 targetDate={new Date(user.airdrop_end_date)} /> </h1>

                }


                <h3 className='text-white text-center'>{user.airdrop_title}</h3>
                <img src={user.airdrop_image} width="100%" className='mt-3' />
                {/* <p className='text-center mt-5 pb-3' >🥳 40000 $DGH Airdrop Giveaway 🥳


              🎉 Wise Crypto is thrilled to announce partnership with Digipharm!!

              ✨ ﻿﻿DIGIPHARM﻿﻿ help patients access the best available healthcare with our pioneering value-based contracting platform.

              ➡️ $DGH is listed on MEXC
              https://www.mexc.com/support/articles/12969079028889

              🏆 Prize Pool 🏆
              👬 Top 50 referrals will get 200 $DGH each
              👬 300 Random winners will get 100 $DGH each</p> */}


                <p className='text-center mt-5 pb-3' style={{ wordBreak: "break-all" }} >{parse(user && user.airdrop_description)}</p>



                {timeOut ?
                  <>

                    <div class="massive-message light-grey-bg center" ng-show="!campaign.winners &amp;&amp; showPromotionEnded()">
                      <i class="fas fa-flag-checkered" style={{ fontSize: "150px" }}></i>
                      <h2 class="ng-binding">
                        This Competition has ended
                      </h2>
                    </div>


                  </> :
                  <>
                    <div class="row mx-auto">

                      <div className="col-12 pe-0 col-md-6 pb-3 pb-lg-0">

                        <div className="admin-input-spacing py-3">
                          <div htmlFor="smtp_user" className="form-label text-white">
                            <p style={{ color: "white", fontSize: "21px", marginBottom: "1.3rem" }}>$DGH is listed on </p>
                          </div>
                          <div class="bts "><a href="#" class="btn hover-animated px-4">
                            <span class="circle">
                              <span class="ink">
                              </span>
                            </span>
                            <span class="lnk">MEXC</span>
                          </a>
                          </div>

                        </div>


                      </div>
                      <div className="col-12 pe-0 col-md-6">

                        <div className="admin-input-spacing py-3">
                          <div htmlFor="smtp_user" className=" text-white">
                            <p style={{ color: "white", fontSize: "21px", marginBottom: "0.5rem" }}>Prize Pool</p>
                          </div>
                          <div htmlFor="smtp_user" className="form-label" style={{ marginLeft: "40px", marginRight: "40px" }}>
                            <p style={{ color: "#4bffa5", fontSize: "16px" }}><b>Top 50 </b>referrals<br /> will get <b>200 $DGH</b> each</p>
                          </div>

                        </div>



                      </div>
                    </div>
                    {wallet && wallet.length ?
                      <div style={{ textAlign: "center", border: "1px solid #4bffa5", borderRadius: "5px" }} className="my-5 pb-2 ms-3 li metamsk-hover" onClick={handleLogout}>
                        <button style={{ borderBottom: "none", fontSize: "20px" }}>Logout</button>

                      </div>
                      :
                      <div style={{ textAlign: "center", border: "1px solid #4bffa5", borderRadius: "5px" }} className="mt-5 mb-4 py-3 ms-3 pointer metamsk-hover" onClick={handleLogin}><span style={{ fontSize: "20px" }}>Login With :<span> </span><span> </span>
                        <img
                          className="avatar-lg rounded-circle me-3 my-auto ms-3"
                          src={metamask}
                          class="metamsk-image"
                          // src={require("../../../assets/img/faces/3.jpg")}
                          alt="description"
                        />
                      </span>
                      </div>}





                    {/* step  */}
                    <div style={{ textAlign: "center" }} className="ms-3 pb-3 pointer" onClick={handleLogin}><span style={{ fontSize: "20px" }}>
                      4 Ways to Start
                    </span>
                    </div>
                    <div class="border-box"></div>

                    <div className="list-group list-lg-group list-group-flush">
                      <div className="border-0 my-2 ms-4 pointer " onClick={() => handleStep("1")}>
                        <div className="list-group-item list-group-item-action" style={{ backgroundColor: "transparent" }}>
                          <div className="media mt-0">
                            <img
                              className="avatar-lg  me-3 my-auto"
                              src={twitter}
                              // width="7%"
                              alt="description"
                            />
                            <div className="media-body ">
                              <div className="d-flex align-items-center ">
                                <div className="mt-0">

                                  <p className="mb-0 tx-13  text-step " >
                                    Follow On Twitter
                                  </p>
                                </div>
                              </div>
                            </div>
                            {
                              Completestate.step1 == "1" ?
                                <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fa fa-check-circle"></i></div>
                                :
                                dataStatus.step1 ?
                                  <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fas fa-chevron-down"></i></div>
                                  :
                                  <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fas fa-chevron-right"></i></div>
                            }

                          </div>
                        </div>
                      </div>
                      <div class="border-box"></div>
                      {dataStatus.step1 && loginStatus.step1 &&
                        <>
                          <div id="tooltip" className="on right pb-2">
                            <div className="tooltip-arrow"></div><div className="tooltip-inner text-danger py-3">Login is Required!!</div>
                          </div>
                          <div class="border-box"></div>
                        </>
                      }
                      {dataStatus.step1 && cred.step1 && <>

                        <div id="widget" style={{ textAlign: "-webkit-center" }} className="my-3" onClick={handleCredinal}>
                          <div class="btn-o" data-scribe="component:button" >
                            <a class="btn" id="b"><i></i><span class="label" id="l">Log in with Twitter</span></a></div>
                        </div>
                        <div class="border-box"></div>
                      </>}
                      {dataStatus.step1 && own.step1 && <>

                        <div id="widget" style={{ textAlign: "-webkit-center" }} className="my-3" onClick={handleFollow}>
                          <div class="btn-o" data-scribe="component:button" >
                            <a class="btn" id="b"><i></i><span class="label" id="l">Follow</span></a></div>
                        </div>
                        <div class="border-box"></div>
                      </>}

                      <div className="border-0 my-2 ms-4 pointer " onClick={() => handleStep("2")}>
                        <div className="list-group-item list-group-item-action" style={{ backgroundColor: "transparent" }}>
                          <div className="media mt-0">
                            <img
                              className="avatar-lg  me-3 my-auto"
                              src={twitter}
                              // width="7%"
                              alt="description"
                            />
                            <div className="media-body ">
                              <div className="d-flex align-items-center">
                                <div className="mt-0">

                                  <p className="mb-0 tx-13  text-step " >
                                    Like Our Post on Twitter
                                  </p>
                                </div>

                              </div>
                            </div>
                            {
                              Completestate.step2 == "1" ?
                                <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fa fa-check-circle"></i></div>
                                : dataStatus.step2 ?
                                  <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fas fa-chevron-down"></i></div>
                                  :
                                  <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fas fa-chevron-right"></i></div>
                            }
                          </div>
                        </div>

                      </div>
                      <div class="border-box"></div>

                      {
                        dataStatus.step2 && (
                          tooltipStatus.step2 ?
                            <>
                              <div id="tooltip" className="on right py-3">
                                <div className="tooltip-arrow"></div><div className="tooltip-inner ">You need to Complete 1 other actions first</div>
                              </div>
                              <div class="border-box"></div>

                            </> :
                            loginStatus.step2 &&
                            <>
                              <div id="tooltip" className="on right pb-2">
                                <div className="tooltip-arrow"></div><div className="tooltip-inner text-danger py-3">Login is Required!!</div>

                              </div>
                              <div class="border-box"></div>
                            </>
                        )
                      }
                      {dataStatus.step2 && cred.step2 && <>

                        <div id="widget" style={{ textAlign: "-webkit-center" }} className="my-3" onClick={handleCredinal}>
                          <div class="btn-o" data-scribe="component:button" >
                            <a class="btn" id="b"><i></i><span class="label" id="l">Log in with Twitter</span></a></div>
                        </div>
                        <div class="border-box"></div>
                      </>}
                      {dataStatus.step2 && own.step2 && <>

                        <div id="widget" style={{ textAlign: "-webkit-center" }} className="my-3" onClick={handleLike}>
                          <div class="btn-o" data-scribe="component:button" >
                            <a class="btn" id="b"><i></i><span class="label" id="l">Like</span></a></div>
                        </div>
                        <div class="border-box"></div>
                      </>}
                      <div className="border-0 my-2 ms-4 pointer " onClick={() => handleStep("3")}>
                        <div className="list-group-item list-group-item-action" style={{ backgroundColor: "transparent" }}>
                          <div className="media mt-0">
                            <img
                              className="avatar-lg  me-3 my-auto"
                              src={twitter}
                              // width="7%"
                              alt="description"
                            />
                            <div className="media-body ">
                              <div className="d-flex align-items-center">
                                <div className="mt-0">

                                  <p className="mb-0 tx-13  text-step " >
                                    Share Our Post on twitter
                                  </p>
                                </div>

                              </div>
                            </div>
                            {/* <img
                              className="avatar-lg  me-3 mx-auto"
                              src={arrowRight}
                              // width="7%"
                              alt="description"
                            /> */}
                            {
                              Completestate.step3 == "1" ?
                                <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fa fa-check-circle"></i></div>
                                : dataStatus.step3 ?
                                  <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fas fa-chevron-down"></i></div>
                                  :
                                  <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fas fa-chevron-right"></i></div>
                            }
                          </div>
                        </div>

                      </div>
                      <div class="border-box"></div>

                      {
                        dataStatus.step3 && (
                          tooltipStatus.step3 ?
                            <>
                              <div id="tooltip" className="on right py-3">
                                <div className="tooltip-arrow"></div><div className="tooltip-inner ">You need to Complete 2 other actions first</div>
                              </div>
                              <div class="border-box"></div>
                            </>
                            :
                            loginStatus.step3 &&
                            <>
                              <div id="tooltip" className="on right pb-2">
                                <div className="tooltip-arrow"></div><div className="tooltip-inner text-danger py-3">Login is Required!!</div>

                              </div>
                              <div class="border-box"></div>
                            </>
                        )
                      }
                      {dataStatus.step3 && cred.step3 && <>

                        <div id="widget" style={{ textAlign: "-webkit-center" }} className="my-3" onClick={handleCredinal}>
                          <div class="btn-o" data-scribe="component:button" >
                            <a class="btn" id="b"><i></i><span class="label" id="l">Log in with Twitter</span></a></div>
                        </div>
                        <div class="border-box"></div>
                      </>}
                      {dataStatus.step3 && own.step3 && <>

                        <div id="widget" style={{ textAlign: "-webkit-center" }} className="my-3" onClick={handlePost}>
                          <div class="btn-o" data-scribe="component:button" >
                            <a class="btn" id="b"><i></i><span class="label" id="l">Share</span></a></div>
                        </div>
                        <div class="border-box"></div>
                      </>}
                      <div className="border-0 my-2 ms-4  pointer" onClick={() => handleStep("4")}>
                        <div className="list-group-item list-group-item-action" style={{ backgroundColor: "transparent" }}>
                          <div className="media mt-0">
                            <img
                              className="avatar-lg  me-3 my-auto"
                              src={telegram}
                              // width="7%"
                              alt="description"
                            />
                            <div className="media-body ">
                              <div className="d-flex align-items-center">
                                <div className="mt-0">

                                  <p className="mb-0 tx-13  text-step " >
                                    Join Our Channel on Telegram
                                  </p>
                                </div>

                              </div>
                            </div>
                            {
                              Completestate.step4 == "1" ?
                                <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fa fa-check-circle"></i></div>
                                : dataStatus.step4 ?
                                  <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fas fa-chevron-down"></i></div>
                                  :
                                  <div class="avatar-lg  me-3 mx-auto fs-5" style={{ color: "rgb(75, 255, 165)" }}><i class="fas fa-chevron-right"></i></div>
                            }

                          </div>
                        </div>
                      </div>
                      <div class="border-box"></div>


                      {dataStatus.step4 && (
                        tooltipStatus.step4 ?
                          <>
                            <div id="tooltip" className="on right py-3">
                              <div className="tooltip-arrow"></div><div className="tooltip-inner text-white">You need to Complete 3 other actions first</div>
                            </div>
                            <div class="border-box"></div>

                          </> :
                          loginStatus.step4 &&
                          <>
                            <div id="tooltip" className="on right pb-2">
                              <div className="tooltip-arrow"></div><div className="tooltip-inner text-danger py-3">Login is Required!!</div>

                            </div>
                            <div class="border-box"></div>
                          </>
                      )
                      }
                      {/* {dataStatus.step4 && cred.step4 && <>

                        <div id="widget" style={{ textAlign: "-webkit-center" }} className="my-3" onClick={handleCredinal}>
                          <div class="btn-o" data-scribe="component:button" >
                            <a class="btn" id="b"><i></i><span class="label" id="l">Log in with Twitter</span></a></div>
                        </div>
                        <div class="border-box"></div>
                      </>} */}
                      {dataStatus.step4 && own.step4 && <>

                        <div id="widget" style={{ textAlign: "-webkit-center" }} className="my-3" onClick={handleJoin}>
                          <div class="btn-o" data-scribe="component:button" >
                            <a class="btn" id="b"><i></i><span class="label" id="l">Join</span></a></div>
                        </div>
                        <div class="border-box"></div>
                      </>}
                    </div>


                  </>}
              </div>

            </div>
          </div>
        </Layout>
      }
    </>
  )
}

export default AirDrop