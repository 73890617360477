import React from 'react'
import Layout from '../layout/Layout'
import About from './About'
import client1 from "../images/client1.png"
import client2 from "../images/client2.png"
import client3 from "../images/client3.png"
import client4 from "../images/client4.png"
const Partner = () => {
    return (
        <Layout>
            <div className="section started" id="section-started">
                {/* background */}

                <div class="section " id="section-clients" style={{ paddingTop: "12%" }}>
                    <div class="content">

                        {/* <!-- title --> */}
                        <div class="title">
                            <div class="title_inner">Partner</div>
                        </div>

                        {/* <!-- clients items --> */}
                        <div class="content-box">
                            <div class="clients-items">

                                <div class="clients-col">
                                    <div class="clients-item">
                                        <a target="_blank" href="/">
                                            <img src={client1} alt="" />
                                        </a>
                                    </div>
                                </div>

                                <div class="clients-col">
                                    <div class="clients-item">
                                        <a target="_blank" href="/">
                                            <img src={client3} alt="" />

                                        </a>
                                    </div>
                                </div>

                                <div class="clients-col">
                                    <div class="clients-item">
                                        <a target="_blank" href="/">
                                            <img src={client2} alt="" />

                                        </a>
                                    </div>
                                </div>

                                <div class="clients-col">
                                    <div class="clients-item">
                                        <a target="_blank" href="/">
                                            <img src={client4} alt="" />

                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="clear"></div>
                    </div>
                </div>


            </div>

            {/* <About />
      <Porfolio />
      <Contact />
      <Error /> */}
        </Layout>
    )
}

export default Partner